<template>
  <div class="order-list-wrapper">
    <en-table-layout :tableData="pageData.data" :loading="loading" :span-method="orderSpanMethod">
      <template slot="toolbar">
        <el-form-item label="订单状态" class="col-auto">
          <el-select
            style="width: 100px;"
            v-model="params.order_status"
            size="medium"
          >
            <template v-if="activeName === '1'">
              <el-option
                v-show="item.label!=='待付款'"
                v-for="item in MixinOrderStatusOptions[0]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </template>
            <template v-else>
              <el-option
                v-show="item.label!=='待付款'"
                v-for="item in MixinOrderStatusOptions[1]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </template>
          </el-select>
        </el-form-item>

        <el-form-item label="下单日期" class="col-auto">
          <el-date-picker
            style="width: 220px"
            v-model="advancedForm.order_time_range"
            type="daterange"
            align="center"
            size="medium"
            :editable="false"
            unlink-panels
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="关键词" class="col-auto" size="medium">
          <el-input
            style="width: 250px"
            v-model="advancedForm.keywords"
            placeholder="请输入关键词"
            clearable
            size="medium"
          >
            <el-select style="width: 130px;" v-model="search_type" slot="prepend" size="medium">
              <el-option label="订单编号" value="order_sn"></el-option>
              <el-option label="买家账号" value="buyer_name"></el-option>
              <el-option label="商品名称" value="goods_name"></el-option>
              <el-option label="物流单号" value="ship_no"></el-option>
              <el-option label="收货人手机号" value="ship_mobile"></el-option>
              <el-option label="卡券卡号" value="card_code"></el-option>
            </el-select>
          </el-input>
        </el-form-item>

        <div class="col-auto">
          <el-button @click="advancedSearchEvent();" size="small" type="primary">
            搜索
          </el-button>
        </div>

        <div class="col"></div>

        <div class="col-auto">
          <el-button
            @click="ziyousubmitImport"
            v-if="activeName === '2'"
            :loading="importLoading"
            size="small"
            type="primary"
          >
            导出订单
          </el-button>

          <el-button
            v-if="activeName === '2'"
            @click="batchImport = true; funcName = 'importAndShipSellerOrder';"
            size="small"
            type="primary"
          >
            导入订单并发货
          </el-button>
        </div>
      </template>

      <template slot="table-columns">

        <el-table-column label="订单编号" prop="trade_sn" width="180" fixed="left">
          <template slot-scope="{row}">
            <span class="ml-2">{{ row.trade_sn }}</span>
          </template>
        </el-table-column>

        <!--        <el-table-column label="订单总额" width="100" fixed="left">-->
        <!--          <template slot-scope="{row}">-->
        <!--            <template v-if="activeName === '1'">-->
        <!--              {{ row.shop_combo_order_price | unitPrice('￥') }}-->
        <!--            </template>-->
        <!--            <template v-else>-->
        <!--              {{ (shopInfo.shop_type === 2 ? row.supplier_order_price : row.order_price) | unitPrice('￥') }}-->
        <!--            </template>-->
        <!--          </template>-->
        <!--        </el-table-column>-->

        <el-table-column label="图片" width="60" class-name="goods-cover-wrapper" fixed="left">
          <template slot-scope="{row}">
            <el-popover
              placement="right"
              trigger="hover"
            >
              <img :src="row.image || row.goods_image" style="width: 300px;" alt=""/>
              <img slot="reference" :src="row.image || row.goods_image" class="goods-cover" alt=""/>
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="商品名称" prop="goods_name" show-overflow-tooltip width="200" fixed="left">
          <template slot-scope="{row}">
            {{ row.goods_name && row.goods_name.replaceAll('amp;','').replaceAll(' ','&nbsp;') }}
          </template>
        </el-table-column>
        <el-table-column label="商品前端名称" prop="goods_alias" show-overflow-tooltip width="200">
          <template slot-scope="{row}">
                {{ row.goods_alias && row.goods_alias.replaceAll('amp;','').replaceAll(' ','&nbsp;') }}
              </template>
          </el-table-column>
        <el-table-column label="商品单价" width="100">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              <template v-if="shopInfo.shop_type !== 2">
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.shop_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.revise_exchange_money | unitPrice('￥') }} +
                  {{ row.revise_exchange_point }}积分
                </div>
              </template>
              <template v-else>
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.supplier_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.exchange_money | unitPrice('￥') }} +
                  {{ row.exchange_point }}积分
                </div>
              </template>
            </template>

            <template v-else>
              <template v-if="shopInfo.shop_type !== 2">
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.enterprise_purchase_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.revise_exchange_money | unitPrice('￥') }} +
                  {{ row.revise_exchange_point }}积分
                </div>
              </template>
              <template v-else>
                <div v-if="row.goods_type === 'NORMAL'">
                  <span>{{ row.supplier_price | unitPrice('￥') }}</span>
                </div>
                <div v-else>
                  {{ row.exchange_money | unitPrice('￥') }} +
                  {{ row.exchange_point }}积分
                </div>
              </template>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="商品数量" prop="goods_num" width="80"/>

        <el-table-column label="物流状态" width="80">
          <template slot-scope="{row}">
            <span v-if="row.logistics_status_text">{{row.logistics_status_text}}</span>
            <span v-else>{{ row.state === 0 ? '未发货' : (row.state === 2 ? '已发货' : '已收货') }}</span> 
          </template>
        </el-table-column>

        <el-table-column label="物流单号" width="300" show-overflow-tooltip>
          <template slot-scope="{row}">
            <template v-if="row.delivery">
              {{ row.delivery.logiName }}: {{ row.delivery.deliveryNo }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="供货方" width="80">
          <template slot-scope="{row}">
            {{ row.order_source === 0 ? '平台' : (row.order_source === 1 ? '京东' : '自有')  }}
          </template>
        </el-table-column>

        <el-table-column label="订单状态" width="80">
          <template slot-scope="{row}">
            <template v-if="activeName === '1'">
              {{ row.trade_status_text }}
              <div>
                <span style="color: red;">
                  {{ row.hangup_status === 1 ? '(店铺挂起)' :
                  (row.hangup_status === 2 ? '(企业挂起)' : '')}}
                </span>
              </div>
<!--              <template v-if="row.trade_status !== 'CANCELLED'">-->
<!--                {{ row.trade_status_text }}-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <span>{{ row.trade_status_text }}</span><br>-->
<!--                <span v-if="row.hangup_status !== 0" style="color: red;">(系统原因)</span>-->
<!--              </template>-->
            </template>
            <template v-else>
              {{ row.order_status_text }}
            </template>
          </template>
        </el-table-column>

        <el-table-column label="下单时间" width="160">
          <template slot-scope="{row}">
            {{ row.create_time | unixToDate }}
          </template>
        </el-table-column>

        <el-table-column label="用户账号" prop="member_name" width="150" show-overflow-tooltip
                         class-name="font-family-tttgb"/>

        <el-table-column label="用户备注信息" width="160">
          <template slot-scope="{row}">
            <el-tooltip class="item" effect="dark" :content="row.remark" placement="top">
              <div class="remark-box">
                {{row.remark}}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>

        <!-- 分销利润 和 商城利润 -->
        <template v-if="shopInfo.shop_type === 3 || (activeName === '2' && MixinIsFormEnterprise)">
          <el-table-column label="分销利润" width="100">
            <template slot-scope="{row}">
              <template v-if="activeName === '1'">
                {{ (row.goods_price - row.enterprise_goods_price).toFixed(2) | unitPrice('￥') }}
              </template>
              <template v-else>0</template>
            </template>
          </el-table-column>

          <el-table-column label="商城利润" width="100">
            <template slot-scope="{row}">
              <template v-if="activeName === '1'">
                {{ (row.revise_price - row.enterprise_total_price).toFixed(2) | unitPrice('￥') }}
              </template>
              <template v-else>
                {{ (row.revise_price - row.enterprise_purchase_price).toFixed(2) | unitPrice('￥') }}
              </template>
            </template>
          </el-table-column>
        </template>

        <el-table-column label="操作" :width="activeName === '2' ? 170 : 195" fixed="right">
          <template slot-scope="{row}">
            <el-button type="primary" size="small" @click="handleOperateOrder(row)">
              查看详情
            </el-button>
            <el-button v-if="row.isCancelOrder === 101" type="primary" size="small" @click="cancelOrder(row)">
              取消订单
            </el-button>
            <el-button v-if="row.isCancelOrder === 103" disabled type="primary" size="small">
              取消订单
            </el-button>
            <!--            <el-button-->
            <!--              v-if="activeName === '2' && row.order_status === 'PAID_OFF'"-->
            <!--              type="warning"-->
            <!--              size="small"-->
            <!--              @click="deliverDialog(row.sn, ship_no)"-->
            <!--            >-->
            <!--              发货-->
            <!--            </el-button>-->
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="pageData.page_no"
        :page-size="pageData.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="pageData.data_total"
      />
    </en-table-layout>

    <x-dialog :proxy="orderPublishDialog">
      <!--      <template slot="header-actions">-->
      <!--        <el-button-->
      <!--          type="primary"-->
      <!--          size="small"-->
      <!--          :disabled="!selectList.length || orderDetail.pay_status !== 'PAY_YES'"-->
      <!--          @click="submitFhing"-->
      <!--        >-->
      <!--          确认发货-->
      <!--        </el-button>-->
      <!--      </template>-->

      <div v-if="$store.getters.shopInfo.shop_type !== 1">

        <en-table-layout
          ref="multipleTable"
          :table-data="sku_list"
          @selection-change="handleSelectionChange"
        >

          <template slot="toolbar">
            <div class="col"></div>

            <el-button
              type="primary"
              size="small"
              :disabled="!selectList.length || orderDetail.pay_status !== 'PAY_YES'"
              @click="submitFhing"
            >
              确认发货
            </el-button>
          </template>

          <template slot="table-columns">
            <el-table-column :selectable="checkboxT" type="selection" width="45"></el-table-column>
            <el-table-column prop="sku_sn" label="商品编号" width="150"></el-table-column>
            <el-table-column prop="name" label="商品名称以及规格" min-width="200">
              <template slot-scope="{row}">
                {{ row.goods_name && row.goods_name.replaceAll('amp;','').replaceAll(' ','&nbsp;') }}
              </template>
            </el-table-column>
            <el-table-column prop="num" label="商品数量" width="100"></el-table-column>
            <el-table-column label="物流公司" width="200">
              <template slot-scope="scope">
                <el-select
                  size="mini"
                  :disabled="scope.row.state === 3"
                  v-model="scope.row.logi_id"
                  placeholder="请选择"
                  @change="filterLogisticsData(scope.row)"
                >
                  <el-option
                    v-for="item in logisticsData"
                    :key="item.logi_id"
                    :label="item.name"
                    :value="item.logi_id"
                  />
                </el-select>
                <!--                <input type="text" style="display: none" v-model="scope.row.logi_name"/>-->
              </template>
            </el-table-column>

            <el-table-column label="物流操作" width="260px">
              <template slot-scope="scope">
                <ul class="wlcz" style="list-style: none; padding-left: 10px; margin-bottom: 0;">
                  <li v-for="(item, index) in scope.row.delivery_list" :key="index" style="display: flex;">
                    <el-input :disabled="scope.row.state === 3" size="mini" placeholder="请输入物流单号"
                              v-model="item.delivery_no"></el-input>
                  </li>
                </ul>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </div>
    </x-dialog>

    <el-dialog
      :title="
        funcName === 'importAndShipSellerOrder'
          ? '批量导入并发货'
          : '导入历史物流信息'
      "
      :visible.sync="batchImport"
      width="1000px"
      @closed="importData = []"
    >
      <div class="buttonAll">
        <upload-excel-component :on-success="excelSuccess"/>
      </div>
      <el-table :data="importData">
        <el-table-column label="序号">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          v-for="(item, index) in templateKey"
          :prop="item"
          :key="index"
          :label="templateHedaer[index]"
        ></el-table-column>
        <el-table-column prop="errorText" label="校验标识"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batchImport = false">取 消</el-button>
        <el-button type="primary" @click="submitFh">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="funcName === 'importAndShipSellerOrder' ? '批量导入并发货' : '导入历史物流信息'" :visible.sync="batchImport"
               width="1000px" @closed="importData = []">
      <div class="buttonAll">
        <upload-excel-component :on-success="excelSuccess"/>
      </div>
      <el-table :data="importData"
                :header-cell-style="{textAlign: 'center', backgroundColor: 'rgba(230, 236, 247, 1)'}">
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column v-for="(item, index) in templateKey" :prop="item" :key="item" align="center"
                         :label="templateHedaer[index]"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="batchImport = false">取 消</el-button>
        <el-button type="primary" @click="submitFh">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import * as API_order from '@/api/order';
import * as API_logistics from '@/api/expressCompany';
import {handleDownload, mergeSkuList} from '@/utils';
import {Foundation} from '@/../ui-utils';
import {CategoryPicker} from '@/components';
import UploadExcelComponent from '@/components/UploadExcel';
import EnTableLayout from '../../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import {$xDialog} from '@/components/x-dialog/dialog.proxy';
// import * as API_Member from '@/api/member';
// import * as API_Goods from '@/api/goods';

export default {
  name: 'orderList',
  components: {
    XDialog,
    EnTableLayout,
    [CategoryPicker.name]: CategoryPicker,
    UploadExcelComponent,
  },
  computed: {
    ...mapGetters(['shopInfo']),
    timeDefault() {
      const date = new Date();
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + (date.getDate() - 1);
    },
  },
  data() {
    return {
      isCancelBtn: null,
      /** 列表loading状态 */
      loading: false,
      importLoading: false,
      dialogVisible: false,
      orderPublishDialog: $xDialog.create({
        title: '订单发货',
        width: '80vw',
        disableConfirm: true,
        disableCancel: true,
        showClose: true,
      }),

      activeName: '1',

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        self_goods: 0,
        order_status: 'ALL',
        ...this.$route.query,
      },

      /** 列表分页数据 */
      pageData: {data: []},
      comParams: {
        cgrade: 2,
        page_no: 1,
        page_size: 20,
      },
      /** 高级搜索数据 */
      advancedForm: {
        shop_member_unit_id: '',
        shop_member_unit_id_two: '',
        order_time_range: [],
      },
      batchImport: false,
      importData: [],
      isError: false,
      templateHedaer: [
        '商品名称以及规格',
        '商品编号',
        '数量',
        '详细地址',
        '姓名',
        '电话',
        '订单号',
        '订单状态',
        '物流公司',
        //"物流状态",
        '物流单号',
        '买家账号',
      ],
      templateKey: [
        'name',
        'sku_sn',
        'num',
        'ship_addr',
        'ship_name',
        'ship_mobile',
        'sn',
        'order_status_text',
        'logi_name',
        //"ship_status_text",
        'ship_no',
        'member_name',
      ],
      funcName: '',
      /** 表格最大高度 */
      tableMaxHeight: '',
      search_type: 'order_sn',
      obj: {},
      /*订单商品数据*/
      sku_list: [],
      selectList: [],
      ship_no: '',
      /** 物流信息 */
      logisticsData: [],
      formInline: {
        logi_id: '',
      },
      orderDetail: {},
    };
  },
  watch: {},
  mounted() {
    delete this.params.market_enable;
    let {type} = this.$route.query;
    this.params = {
      ...this.params,
      ...this.$route.query,
    };

    if (this.shopInfo.shop_type === 1 || this.shopInfo.shop_type === 4) {
      this.params['shop_type'] = 1
    } else if (this.shopInfo.shop_type === 3) {
      this.params['shop_type'] = 2
    }

    const arr = [];

    switch (type) {
      case '1':
        this.params.order_status = 'PAID_OFF';
        break;
      case '3':
        this.params.order_status = 'ALL';
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date = new Date(); //获取Date对象
        now_date.setHours(0); //设置小时
        now_date.setMinutes(0); //设置分钟
        now_date.setSeconds(0); //设置秒
        now_date.setMilliseconds(0); //设置毫妙
        let timestamp = now_date.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp - 86400,
          end_time: timestamp - 1,
        };
        break;
      case '4':
        this.params.order_status = 'COMPLETE';
        arr.push(this.timeDefault);
        arr.push(this.timeDefault);
        // 初始化搜索，默认为前一天
        this.advancedForm.order_time_range = arr;
        let now_date1 = new Date(); //获取Date对象
        now_date1.setHours(0); //设置小时
        now_date1.setMinutes(0); //设置分钟
        now_date1.setSeconds(0); //设置秒
        now_date1.setMilliseconds(0); //设置毫妙
        let timestamp1 = now_date1.getTime() / 1000; //获取毫秒时间戳
        this.params = {
          ...this.params,
          start_time: timestamp1 - 86400,
          end_time: timestamp1 - 1,
        };
        break;
      default:
        break;
    }

    this.GET_OrderList('1')
    this.getLogisticsCompanies();
  },
  beforeRouteUpdate(to, from, next) {
    delete this.params.market_enable;
    this.params = {
      ...this.params,
      ...this.$route.query,
    };
    this.GET_OrderList();
    next();
  },
  methods: {
    cancelOrder(row){
      this.$confirm('确定要取消这个订单？', '提示', { type: 'warning' }).then(() => {
        // return console.log(row.trade_sn,row.card_code,row.shop_combo_id,row)
        let params = {
          trade_sn: row.trade_sn ,
          card_code: row.card_code,
          combo_id : row.shop_combo_id,
          reason: "退款原因"
        }
        API_order.cancelOrder(params).then((res)=>{
          if(res.statusCode !== 101) return this.$message.error(res.statusMsg)
          this.$message.success(res.statusMsg)
          this.GET_OrderList();
        });
      })
    },
    orderSpanMethod(
      {
        row,
        column,
        rowIndex,
        columnIndex
      }
    ) {
      if (columnIndex >= 1 && columnIndex <= 8) {
        return {
          rowspan: 1,
          colspan: 1,
        };
      } else {
        return row.span;
      }
    },
    /** 获取物流公司信息列表 */
    getLogisticsCompanies() {
      API_logistics.getExpressCompanyList({}).then((res) => {
        this.logisticsData = res;
      });
    },
    deliverDialog(sn, ship_no) {
      this.sku_list = []
      this.sn = sn;
      this.ship_no = ship_no;

      this.orderPublishDialog.display();

      API_order.getOrderItemsList(sn).then((response) => {
        if (response && response.length) {
          let sku_list = [...response];
          sku_list.forEach((item) => {
            if (!item.delivery_list || !item.delivery_list.length || !item.delivery_list[0].delivery_no) {
              item.delivery_list = [{}];
              this.sku_list.push(item)
            }
          });
        }
      });

      if (this.shopInfo.shop_type === 2) {
        API_order.getSellerOrderDetail(sn).then((response) => {
          // 订单信息
          if (response.order_status === 'CANCELLED' || response.service_status === 'APPLY') {
            this.$message.error('该订单已取消，不需要发货')
          }
          this.orderDetail = response;
        });
      } else {
        API_order.getOrderDetail(sn).then((response) => {
          // 订单信息
          if (response.order_status === 'CANCELLED' || response.service_status === 'APPLY') {
            this.$message.error('该订单已取消，不需要发货')
          }
          this.orderDetail = response;
        });
      }
    },

    handleSelectionChange(list) {
      this.selectList = list;
    },

    checkboxT(row, index) {
      return row.state !== 3;
    },

    submitFhing() {
      for (let i = 0; i < this.selectList.length; i++) {
        let index = i;
        let item = this.selectList[i];
        if (!item.logi_id) {
          this.$message.error('请选择物流公司');
          return false;
        }
        if (item.delivery_list) {
          for (let j = 0; j < item.delivery_list.length; j++) {
            let item1 = item.delivery_list[j];
            if (!item1.delivery_no) {
              this.$message.error('请输入物流单号');
              return false;
            }
          }
        }
      }
      this.$confirm('确认发货?', '提示', {type: 'warning'}).then(() => {
        let fhInfo = {
          order_sn: this.sn,
          delivery_dolist: [],
        };
        this.selectList.forEach((item, index) => {
          item.delivery_list.forEach((item1, index1) => {
            fhInfo.delivery_dolist.push({
              logi_id: item.logi_id,
              logi_name: item.logi_name,
              order_sn: this.sn,
              sku_id: item.product_id,
              goods_id: item.goods_id,
              goods_name: item.name,
              goods_sn: item.sku_sn,
              ship_num: item.num,
              delivery_no: item1.delivery_no,
            });
          });
        });
        API_order.deliveryNew(fhInfo).then(() => {
          this.$message.success('发货成功');
          this.GET_OrderList('2');
          this.dialogVisible = false;
        });
      });
    },

    filterLogisticsData(row) {
      this.logisticsData.forEach((res) => {
        if (res.logi_id === row.logi_id) {
          row.logi_name = res.name;
        }
      });
    },

    resetSelfGoods() {
      const route = this.$route.path.split('/')[2];
      if (route === 'order-lista') {
        this.params.self_goods = 0;
      } else if (route === 'order-listb') {
        this.params.self_goods = 1;
      }
    },

    // 标签切换
    handleClick(tab) {
      this.params = {
        page_no: 1,
        page_size: 20,
        self_goods: 0,
        order_status: 'ALL',
        ...this.$route.query,
      }

      if (this.activeName === '1') {
        this.params['shop_type'] = 2
      }

      this.pageData.data = [];
      this.GET_OrderList(tab.name);
    },

    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_no = 1
      this.params.page_size = size;
      this.GET_OrderList(this.activeName);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_OrderList(this.activeName);
    },

    /** 订单状态改变 */
    changeOrderStatus(data) {
      delete this.params.keywords;
      delete this.params.order_status;
      if (data) {
        this.params = {
          ...this.params,
          order_status: data,
        };
        this.params.page_no = 1;
        this.params.page_size = 10;
      }
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.GET_OrderList();
    },

    /** 搜索事件触发 */
    searchEvent(data) {
      this.params = {
        ...this.params,
        keywords: data,
      };
      delete this.params.order_status;
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.GET_OrderList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent() {
      this.params.booking_shipment = 0;

      this.obj = {};
      if (this.search_type) {
        this.obj[this.search_type] = this.advancedForm.keywords;
      }
      this.params = {
        ...this.params,
        ...this.advancedForm,
        ...this.obj,
      };
      delete this.params.start_time;
      delete this.params.end_time;
      delete this.params.deliverStartTime;
      delete this.params.deliverEndTime;
      if (this.advancedForm.order_time_range && this.advancedForm.order_time_range.length) {
        if (typeof (this.advancedForm.order_time_range[0]) === 'string') {
          this.params.start_time = new Date(this.advancedForm.order_time_range[0]).getTime() / 1000
          this.params.end_time = new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 + 86400
        } else {
          this.params.start_time = this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_time = this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.keywords;
      delete this.params.order_time_range;
      this.params.page_no = 1;
      if (this.activeName === '1') {
        this.GET_OrderList('1');
      } else {
        this.GET_OrderList('2');
      }

    },

    /** 查看、操作订单 */
    handleOperateOrder(item) {
      let route = this.$route.path.split('/')[2];
      if (this.activeName === '1') {
        this.$router.push({path: `/combo-card-shop/order/${route}/detail/${item.trade_sn}`});
      } else {
        this.$router.push({path: `/order/${route}/detail/${item.trade_sn}?type=${this.activeName}`});
      }
    },

    excelSuccess({results}) {
      this.isError = false;
      let resultsList = [];
      results.forEach((list) => {
        // console.log(list);
        this.templateKey.forEach((item, index) => {
          list = JSON.parse(
            JSON.stringify(list).replace(this.templateHedaer[index], item)
          );
          // if(!list[item]){
          // 	list.errorText = this.templateHedaer[index] + ' 必填'
          // 	this.isError = true
          // }
        });
        if (list.order_sn && !list['ship_num']) {
          list.errorText = '发货数量必填';
          this.isError = true;
        } else if (Number(list['ship_num']) > Number(list['num'])) {
          list.errorText = '发货数量大于订单数量';
          this.isError = true;
        }
        if (list.order_sn && !list['logi_name']) {
          list.errorText = '物流公司必填';
          this.isError = true;
        }
        if (list.order_sn && !list['delivery_no']) {
          list.errorText = '物流单号必填';
          this.isError = true;
        }

        resultsList.push(list);
      });
      this.importData = resultsList;
    },

    submitFh() {
      if (!this.importData.length) {
        this.$message.error('请导入数据');
      } else if (this.isError) {
        this.$message.error('请按校验标识检查数据重新导入');
      } else {
        let data = [];
        this.importData.forEach((item) => {
          data.push({
            order_sn: item.order_sn,
            goods_name: item.goods_name,
            goods_sn: item.goods_sn,
            ship_num: item.ship_num,
            logi_name: item.logi_name,
            delivery_no: item.delivery_no,
          });
        });
        data = data.filter((res) => {
          return res.order_sn;
        });
        API_order[this.funcName]({delivery_dolist: data}).then((res) => {
          this.$message.success('操作成功');
          this.batchImport = false;
          this.GET_OrderList();
        });
      }
    },
    //导出
    submitImport() {
      this.importLoading = true;
      const {
        orderFunc,
        tHeaders,
        filterVals
      } = getExcelMetadata(this.shopInfo.shop_type);

      let params = {...this.params};
      params.page_no = 0;
      params.page_size = 0;
      API_order[orderFunc](params).then((res) => {
        const data = resetExportData(res.data, this);
        handleDownload(data, tHeaders, filterVals, '订单列表');
        this.importLoading = false;
      }).catch((res) => {
        this.importLoading = false;
      });
    },
    GET_OrderList(tab = this.activeName) {
      this.loading = true;

      this.resetSelfGoods();
      console.log(tab)
      // 企业平台商品，站点
      if (tab === '1') {
        delete this.params.keywords;
        delete this.params.shop_member_unit_id_two;
        delete this.params.shop_member_unit_id;
        delete this.params.booking_shipment;
        delete this.params.type;

        API_order.getOrderList(this.params).then((res) => {
          this.loading = false;

          this.pageData = res;
          this.pageData.data = mergeSkuList(res.data, 1);
          console.log(this.pageData.data)
          delete this.params.order_sn;
          delete this.params.buyer_name;
          delete this.params.goods_name;
          delete this.params.ship_no;
          delete this.params.ship_no;
        });
      } else {
        // 企业自由商品
        API_order.getSellerOrderList(this.params).then(res => {
          this.loading = false;

          this.pageData = res;
          this.pageData.data = mergeSkuList(res.data, 2);

          delete this.params.order_sn;
          delete this.params.buyer_name;
          delete this.params.goods_name;
          delete this.params.ship_no;
        })
      }
    },
    // 自有订单导出
    ziyousubmitImport() {
      this.importLoading = true;

      const {
        orderFunc,
        filterVals,
        tHeaders
      } = getExcelMetadata(null, 1);

      let params = {...this.params};
      params.page_no = 0;
      params.page_size = 0;
      API_order[orderFunc](params).then((res) => {
        handleDownload(res.data, tHeaders, filterVals, '订单列表');
        this.importLoading = false;
      }).catch((res) => {
        this.importLoading = false;
      });
    },
  },
};

function getExcelMetadata(shop_type, type) {
  let orderFunc, filterVals, tHeaders;

  if (shop_type === 2) {
    //供应商
    orderFunc = 'getSellerOrderList';
    filterVals = [
      // "pay_order_no",
      // "shop_member_type",
      'order_sn',
      'create_time',
      'payment_time',
      'name',
      'jifenType',
      'source',
      'price',
      'point',
      'num',
      'spec_name',
      'sku_sn',
      'spze',
      'spzjf',
      'ship_name',
      'ship_mobile',
      'shipAddr',
      'remark',
      'ship_num',
      'logi_name',
      'ship_no',
      'order_status_text',
      'pay_member_name',
      'pay_status_text',
      'ship_status_text',
      'service_status',
      'payment_type_text',
      'payment_method_name',
      'client_type',
    ];
    tHeaders = [
      // "交易流水号",
      // "下单人身份类型",
      '订单编号',
      '下单时间',
      '支付时间',
      '商品名称',
      '商品类型',
      '商品结算类型',
      '价格',
      '积分',
      '数量',
      '规格',
      '商品编码',
      '商品总额',
      '商品总积分',
      '收货人',
      '收货人联系方式',
      '收货地址',
      '订单备注',
      '发货数量',
      '物流公司',
      '物流单号',
      '订单状态',
      '付款人',
      '付款状态',
      '发货状态',
      '售后状态',
      '支付方式',
      '支付渠道',
      '订单来源',
    ];
  } else if (shop_type !== null) {
    orderFunc = 'getOrderList';
    filterVals = [
      // "pay_order_no",
      // "shop_member_type",
      'sn',
      'create_time',
      'payment_time',
      'goodnweeess',
      'jifenType',
      'source',
      'revise_exchange_money',
      'revise_exchange_point',
      'num',
      'spec_name',
      'sku_sn',
      'spze',
      'spzjf',
      'shop_order_price',
      'shop_consume_point',
      'shop_deduct_money',
      'discount_price',
      'ship_name',
      'ship_mobile',
      'shipAddr',
      'remark',
      'logi_name',
      'ship_no',
      'order_status_text',
      'pay_member_name',
      'pay_status_text',
      'ship_status_text',
      'service_status',
      'payment_type_text',
      'payment_method_name',
      'client_type',
      'nick_name',
      'mobile',
      'city',
      'shop_member_unit_name',
      'shop_member_unit_name_two',
      'shop_member_com_name',
      'shop_member_part_name',
      'receipt_type',
      'receipt_title',
      'receipt_amount',
      'receipt_content',
      'tax_no',
      'reg_addr',
      'reg_tel',
      'bank_name',
      'bank_account',
    ];
    tHeaders = [
      // "交易流水号",
      // "下单人身份类型",
      '订单编号',
      '下单时间',
      '支付时间',
      '商品名称',
      '商品类型',
      '商品结算类型',
      '价格',
      '积分',
      '数量',
      '规格',
      '商品编码',
      '商品总额',
      '商品总积分',
      '订单总额',
      '订单总积分',
      '订单现金抵扣',
      '优惠抵扣金额',
      '收货人',
      '收货人联系方式',
      '收货地址',
      '订单备注',
      '物流公司',
      '物流单号',
      '订单状态',
      '付款人',
      '付款状态',
      '发货状态',
      '售后状态',
      '支付方式',
      '支付渠道',
      '订单来源',
      '员工姓名',
      '手机号',
      '城市',
      '单位名称',
      '二级单位',
      '企业名称',
      '部门名称',
      '发票类型',
      '发票抬头',
      '发票金额',
      '发票内容',
      '税号',
      '注册地址',
      '注册电话',
      '开户银行',
      '银行账号',
    ];
  }

  if (type === 1) {
    orderFunc = 'getSellerOrderList';
    filterVals = [
      'name',
      'sku_sn',
      'num',
      'ship_addr',
      'ship_name',
      'ship_mobile',
      'order_sn',
      'order_status_text',
      'logi_name',
      //'ship_status_text',
      'ship_no',
      'member_name',
    ];
    tHeaders = [
      '商品名称以及规格',
      '商品编号',
      '数量',
      '详细地址',
      '姓名',
      '电话',
      '订单号',
      '订单状态',
      '物流公司',
      //'物流状态',
      '物流单号',
      '买家账号',
    ];
  }

  return {
    orderFunc,
    filterVals,
    tHeaders
  };
}

function resetExportData(data, ctx) {
  return data.map((item, index) => {
    item = item || {
      isNull: 0,
      ship_province: '',
      ship_city: '',
      ship_county: '',
      ship_town: '',
      ship_addr: '',
      shipAddr: '',
      sku_list: [{
        spec_list: [],
        spec_name: '',
        name: '',
        sku_sn: '',
        service_status: '',
        goods_type: '',
        source: '',
        revise_price: '',
        revise_exchange_money: '',
        num: '',
        revise_exchange_point: '',
      }],
    };

    if (ctx.shopInfo.shop_type !== 2) {
      // 店铺
      if (!item.isNull) {
        item.shipAddr = [item.ship_province, item.ship_city, item.ship_county, item.ship_town, item.ship_addr].join('');
        item.sku_list.forEach((goods) => {
          if (goods.spec_list) {
            goods.spec_name = goods.spec_list.map((lab) => {
              return lab.spec_name + '：' + lab.spec_value;
            }).join('; ');
          }

          if (item.goodnweeess) {
            // item.goodnweeess = item.goodnweeess+' / '+tem.name+x+' '+tem.revise_exchange_money+'x'+tem.num
            return {
              isNull: 1,
              create_time: item.create_time,
              payment_time: item.payment_time,
              // shop_order_price: item.shop_order_price,
              // shop_consume_point: item.shop_consume_point,
              // discount_price: item.discount_price,
              ship_name: item.ship_name,
              ship_mobile: item.ship_mobile,
              shipAddr: item.shipAddr,
              remark: item.remark,
              logi_name: item.logi_name,
              ship_no: item.ship_no,
              pay_member_name: item.pay_member_name,
              pay_status_text: item.pay_status_text,
              ship_status_text: item.ship_status_text,
              payment_type_text: item.payment_type_text,
              payment_method_name: item.payment_method_name,
              client_type: item.client_type,
              nick_name: item.nick_name,
              mobile: item.mobile,
              city: item.city,
              shop_member_com_name: item.shop_member_com_name,
              shop_member_part_name: item.shop_member_part_name,
              // receipt_type: item.receipt_type,
              // receipt_title: item.receipt_title,
              // receipt_amount: item.receipt_amount,
              // receipt_content: item.receipt_content,
              // tax_no: item.tax_no,
              // reg_addr: item.reg_addr,
              // reg_tel: item.reg_tel,
              // bank_name: item.bank_name,
              // bank_account: item.bank_account,
              sn: item.sn,
              order_status_text: item.order_status_text,
              goodnweeess: goods.name,
              sku_sn: goods.sku_sn,
              spec_name: goods.spec_name,
              service_status: ctx.MixinGetOrderStatus(goods.service_status),
              // revise_exchange_money: tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price,
              revise_exchange_money:
                goods.goods_type === 'POINT'
                  ? goods.source === 'CASH'
                  ? goods.revise_price
                  : goods.revise_exchange_money
                  : goods.revise_price,
              num: goods.num,
              // revise_exchange_point: tem.goods_type == 'POINT' ? tem.revise_exchange_point : '',
              revise_exchange_point:
                goods.goods_type === 'POINT'
                  ? goods.source === 'CASH'
                  ? ''
                  : goods.revise_exchange_point
                  : '',
              jifenType:
                goods.goods_type === 'POINT' ? '积分商品' : '普通商品',
              // spze: tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price * tem.num,
              // spzjf: tem.revise_exchange_point * tem.num,
              spze:
                goods.goods_type === 'POINT'
                  ? goods.source === 'CASH'
                  ? goods.revise_price * goods.num
                  : goods.revise_exchange_money
                  : goods.revise_price * goods.num,
              spzjf:
                goods.source === 'CASH'
                  ? ''
                  : goods.revise_exchange_point * goods.num,
              source: goods.source
                ? goods.source === 'CASH'
                  ? '现金结算'
                  : '积分结算'
                : goods.goods_type === 'NORMAL'
                  ? '现金结算'
                  : '积分结算',
            };
          } else {
            item.goodnweeess = goods.name;
            item.spec_name = goods.spec_name;
            item.sku_sn = goods.sku_sn;
            // item.revise_exchange_money = tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price
            (item.revise_exchange_money =
              goods.goods_type === 'POINT'
                ? goods.source === 'CASH'
                ? goods.revise_price
                : goods.revise_exchange_money
                : goods.revise_price);

            (item.num = goods.num);
            // item.revise_exchange_point = tem.goods_type == 'POINT' ? tem.revise_exchange_point : ''

            (item.revise_exchange_point =
              goods.goods_type === 'POINT'
                ? goods.source === 'CASH'
                ? ''
                : goods.revise_exchange_point
                : '');

            (item.jifenType =
              goods.goods_type === 'POINT' ? '积分商品' : '普通商品');
            // item.spze = tem.goods_type == 'POINT' ? tem.revise_exchange_money : tem.revise_price * tem.num
            // item.spzjf = tem.revise_exchange_point * tem.num

            (item.spze =
              goods.goods_type === 'POINT'
                ? goods.source === 'CASH'
                ? goods.revise_price * goods.num
                : goods.revise_exchange_money
                : goods.revise_price * goods.num);

            (item.spzjf =
              goods.source === 'CASH'
                ? ''
                : goods.revise_exchange_point * goods.num);

            (item.service_status = ctx.MixinGetOrderStatus(
              goods.service_status
            ));
            // item.goodnweeess = tem.name+x+' '+tem.revise_exchange_money+'x'+tem.num

            //之前的数据可能没有这个字段 没有你就根据goodstype判断下 goodstype NORMAL 普通商品 之前普通商品只能通过现金结算 POINT 积分商品  之前积分商品只能通过积分结算
            if (!goods.source) {
              if (goods.goods_type === 'NORMAL') item.source = 'CASH';
              if (goods.goods_type === 'POINT') item.source = 'POINT';
            } else {
              item.source = goods.source;
            }

            if (item.source === 'CASH') {
              item.source = '现金结算';
            }
            if (item.source === 'POINT') {
              item.source = '积分结算';
            }
          }

          //下单人身份
          if (item.shop_member_type === 0) {
            item.shop_member_type = '其他会员';
          }
          if (item.shop_member_type === 1) {
            item.shop_member_type = '员工会员';
          }
          if (item.shop_member_type === 2) {
            item.shop_member_type = '工会会员';
          }
          if (item.shop_member_type === 3) {
            item.shop_member_type = '店主或店员';
          }
        });
      }
    } else {
      // 供应商
      item.spec_json = item.spec_json === 'null' ? null : JSON.parse(item.spec_json);

      if (item.spec_json) {
        item.spec_name = item.spec_json.map((lab) => {
          return lab.spec_name + '：' + lab.spec_value;
        }).join('; ');
      }

      item.shipAddr = [item.ship_province, item.ship_city, item.ship_county, item.ship_town, item.ship_addr].join('');
      item.jifenType = item.goods_type === 'POINT' ? '积分商品' : '普通商品';
      item.spze = item.price * item.num;
      item.spzjf = item.point * item.num;
      item.service_status = ctx.MixinGetOrderStatus(item.service_status);

      // 之前的数据可能没有这个字段 没有你就根据goodstype判断下 goodstype NORMAL 普通商品 之前普通商品只能通过现金结算 POINT 积分商品  之前积分商品只能通过积分结算
      if (!item.source) {
        if (item.goods_type === 'NORMAL') item.source = 'CASH';
        if (item.goods_type === 'POINT') item.source = 'POINT';
      }

      if (item.source === 'CASH') {
        item.source = '现金结算';
      }
      if (item.source === 'POINT') {
        item.source = '积分结算';
      }

      //下单人身份
      if (item.shop_member_type === 0) {
        item.shop_member_type = '其他会员';
      }
      if (item.shop_member_type === 1) {
        item.shop_member_type = '员工会员';
      }
      if (item.shop_member_type === 2) {
        item.shop_member_type = '工会会员';
      }
      if (item.shop_member_type === 3) {
        item.shop_member_type = '店主或店员';
      }
    }

    item.create_time = Foundation.unixToDate(
      item.create_time,
      'yyyy-MM-dd hh:mm:ss'
    );

    item.payment_time = Foundation.unixToDate(
      item.payment_time,
      'yyyy-MM-dd hh:mm:ss'
    );

    return item;
  });
}
</script>

<style lang="scss" scoped>
/deep/ {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .goods-cover-wrapper .cell {
    padding-left: 0 !important;
  }
}

.remark-box{
  max-width:150px;
  display:inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
